/* PAGE WIDE STYLINGS */

.writepage {
  width: 100%;
}

.small-text{
  font-size: 10px;
  font-family: sans-serif;
  color: white;
}

.spaced{
  letter-spacing: 5px; 
}

.writepage-container4-wrapper,
.writepage-container6-wrapper,
.writepage-container5-wrapper,
.writepage-container3-wrapper,
.writepage-container2-wrapper,
.writepage-container1-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.writepage-container5-container,
.writepage-container6-container,
.writepage-container4-container,
.writepage-container2-container,
.writepage-container1-container {
  margin-left: 30px;
  margin-right: 20px;
}






/*  DIV 1 stylings */

.writepage-container1-wrapper {
  background-color: #f24d2d;
  padding-top: 50px;
}

.writepage-container1-header {
  margin-top: 50px;
}

.writepage-container1-header2{
  line-height: .9;
  font-size: 60px;
  margin-top: 15px;
}

.writepage-container1-header3{
  font-weight: 300;
  font-size: 20px;
  margin-top: 40px;
  width: 90%;
}

.writepage-container1-button-container{
  margin-top: 22px;
  margin-bottom: 30px;
}


.writepage-started-button {
  cursor: pointer;
  padding: 9px;
  font-size: 22px;
  margin-top: 10px;
  background-color: #ffffff;
  border: #ffffff 1px solid;
  color: #000000;
  border-radius: 20px;
  width: 170px;
  transition: all .5s;
}

.writepage-started-button:hover {
  background-color: #357720;
  color: #ffffff;
  transition: all .5s;

}



/*  DIV 2 stylings */
.writepage-container2-wrapper {

  background-color: #000000;
}

.writepage-container2 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
  background-color: #000000;
}

.writepage-container2-header{

  color: white;
  font-size: 11vw;
  margin-top: 25px;
  margin-bottom: 40px;
}



/*  DIV 3 stylings */

.writepage-container3-container {


  height: fit-content;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: minmax(85px, auto);
  grid-gap: 15px;
  padding-top: 0%;
  padding-bottom: 70px;
  width: 100%;
  background-color: #ffd1b9;
}


@media screen and (max-width: 800px) {
  .writepage-container3-container {
    grid-template-columns: repeat(1, 1fr);
  }
  .writepage-creator-tile {
    width: 50%;
  }
}

.writepage-creator-tile{
  width: 90%;
  height: 70px;
  position: relative;
  left: 5%;
  border-top: rgb(68, 61, 61) solid 1px;
  border-bottom: rgb(37, 35, 35) solid 1px;
  color: white;
  margin-top: 20px;
}

.writepage-creator-tile:last-child{
  margin-bottom: 30px;
}




/*  DIV 4 stylings */

.writepage-container4-wrapper {
  display: flex;
  flex-direction: column;
  text-align: left;
  background-color: #ffd1b8;
  min-height: 400px;
  border-bottom: 1px solid black;
}

.writepage-container4-container {
  margin-top: -40px;
  margin-bottom: 30px;
}

.writepage-container4-header{
  line-height: .9;
  font-size: 12vw;
  width: 100%;
}

.writepage-container4-header2{
  font-weight: 300;
  font-size: 22px ;
  min-width: 300px;
  width: 40%;
  margin-top: 30px;
}


/*  DIV 5 stylings */

.writepage-container5-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 400px;
  border-bottom: 1px solid black;

}

.writepage-container5-container {
  margin-top: 30px;
  margin-bottom: 30px;
}

.writepage-container5-header{
  justify-content: center;
  font-size: 12vw;
  width: 80%;
  line-height: .9;
}

.writepage-container5-header2{
  font-weight: 300;
  font-size: 22px ;
  width: 90%;
  margin-top: 30px;
}

/*  DIV 6 stylings */

.writepage-container6-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 150px;
  border-bottom: 1px solid black;

}

.writepage-container6-container {
  margin-top: 30px;
  margin-bottom: 30px;
}

.writepage-container6-header{
  font-size: 6vw;
  width: 100%;
}

.writepage-container6-header2{
  font-weight: 300;

  font-size: 17px ;
  width: 90%;
  margin-top: 30px;
}

.ourstorypage-footer-note{
  font-size: 15px;
  margin-top: 40px;
}