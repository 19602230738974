.profile-menu {
  /* cursor: pointer; */
  position: fixed;
  top: 60px;
  right: 25px;
  transform: scale(1);
  border: 1px solid rgb(0, 0, 0,0.1);
  /* background-color: rgb(183, 130, 130); */
  border-radius: 5px;
  box-shadow: rgba(127, 127, 127, 0.9) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  width: 140px;
  height: 140px;
  display: flex;
  flex-direction: column;
  z-index: 411;
  background-color: white;
  display: flex;
  justify-content: center;
  /* align-items: center; */
}

.greeting {
  font-family: sans-serif;
  margin-bottom: 10px;
}

.my-stories,
.profile-menu-logout-button {
  margin-bottom: 10px;
  background-color: #f0f0f0;
  /* background-color: #f2f2f2; */
  border-radius: 20px;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  font-family: sans-serif;
}

.my-stories:hover,
.profile-menu-logout-button:hover {
  transition: all 0.2s;
  /* background-color: #f2f2f2; */
  background-color: #e6e6e6
}

.profile-dropdown {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* margin: 30px; */
}

/* .profile-menu-logout-button{
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 80%;
  height: 30%;
  bottom: 10%;
  left: 0%;
  background-color: rgb(74, 113, 186);
  color: white;
  font-weight: bold;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  transition: .1s background-color;
  font-size: 12px;
  bottom: 1px;
} */

/* .profile-menu-logout-button:hover {
  background-color: red;
} */

.ghost-button{
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  border-radius: 15px;
  cursor: pointer;
  top: 22px;
  height: 40px;
  font-size: 13px;
  right: 32px;
  width: 80px;
}

.ghost-button:hover{
  cursor: pointer;
}

.div-link {
  position: relative;
  transition: background-color 0.2s ease;
  padding: 5px;
  left: -40px;
  width: 230px;
  border-bottom-left-radius: 1px;
  border-bottom-right-radius: 1px;
}

.div-link:hover {
  background-color: rgba(228, 228, 228, 0.2508);
}

.div-link:active {
  background-color: rgb(226, 226, 226);
}

.profile-user-welcome {
  position: relative;
  display: flex;
  align-items: center;
  padding: 20px;
  background-color: #f0f0f0;
  border-bottom: 1px solid #dcdcdc;
  padding: 15px;
  left: -40px;
  width: 230px;
  height: 30px;
}

.profile-icon {
  position: relative;
  left: 160px;
  top: 0px;
  transform: scale(2.7);
}

.profile-hello {
  width: fit-content;
  position: absolute;
  margin-bottom: 4px;
  left: 3px;
  font-size: 20px;
}

.profile-email {
  position: absolute;
  width: fit-content;
  left: 5px;
  top: 45px;
  font-size: 14px;
  color: rgb(119, 119, 119);
  font-weight: 600;
  margin-bottom: 4px;
}
.profile-favorites,
.profile-messages,
.profile-reservations {
  position: relative;
  transition: background-color 0.2s ease;
  padding: 5px;
  left: -40px;
  width: 230px;
  border-bottom-left-radius: 1px;
  border-bottom-right-radius: 1px;
}


.profile-messages:hover,
.profile-reservations:hover {
  background-color: rgba(228, 228, 228, 0.2508);
}

.profile-menu-logout-button:active {
  transform: scale(.95);
  background-color: rgb(74, 113, 186);

}


.profile-user-welcome:hover{
  background-color: rgba(228, 228, 228, 0.2508);

}

