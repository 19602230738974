.story-tile-style2 {
  height: fit-content;
  width: 99%;
  left: 0%;
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  background-color: transparent;
}

.story-tile-style2:first-child {
  margin-top: 35px;
}

.story-tile-style2:last-child {
  margin-bottom: 35px;
}

.style2-content{
  margin-left: 20px;

  display: flex;
  flex-direction: column;
  margin-right: 20px;
}

.style2-author-container{
  display: flex;
  align-items: center;
  width: 240px;
  height: 30px;
}

.style2-profile-image{
  width: 30px;
  height: 30px;
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid black;
}


.style2-profile-image img {
  object-fit: cover;
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 300px;
}

.style2-author-name{
  font-size: 14px;
  position: relative;
  margin-left: 10px;
}


.style2-story-title:hover,
.style2-author-name:hover,
.style2-story-image:hover,
.style2-header-content:hover,
.style2-profile-image:hover{
  cursor: pointer;
}

.style2-story-title-container{
  margin-top: 5px;
  height: 50px;
  width: 300px;
  word-break:keep-all;
  text-overflow: ellipsis;
  font-weight: bold;
}



.style2-date-read-time-container{
  width: fit-content;
  align-items: center;
  color: #757575;
  font-size: 13px;
  margin-top: 5px;
  position: relative;
}


@media screen and (min-width: 749px) {
  .style2-date-read-time-container{
    margin-top: 30px;
  }
}

.style2.fa-circle{
  transform: scale(.15);
}





.style2-story-image{
  height: 105px;
  width: 150px;
  right: 0px;
  overflow: hidden;
  border: 1px solid black;
}


.style2-story-image img {
  object-fit: cover;
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 300px;
}


.style2-edit-button{
  position: absolute;
  bottom: 5px;
  border: black solid 1px;
}




/* stylings for larger tile size */
@media screen and (min-width: 700px) {
  .style2-story-image{
    height: 150px;
    width: 200px;
    right: 0px;
  }

  .story-tile-style2 {
    margin-top: 30px;
  }

  .style2-content {
    margin-left: 30px;
    height: auto;
  }


  .style2-story-title-container{
    height: fit-content;
  }



  .style2-story-title{
    font-size: larger;
  }
  
  
  .style2-date-read-time-container{
    margin-top: 5px;
  }

  .style2-header-container{
    width: 400px;
  }

  .style2-header-content{
    margin-top: 3px;
    /* margin-right: 30px; */
    color: #757575;
    /* width: auto; */

  }

}



@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-in {
  animation: fadeIn 1s ease-in;
}


