
.sidepanel-staff-picks-container{
  flex-direction: column;
  display: flex;
  position: relative;
  height: 500px;

}
.sidepanel-banner-cover{
  height:1px;
  background-color: #ffffff;
  width: 100%;
  position: relative;

}

.see-more-topics {
  font-family: sans-serif;
  margin-left: -10px;
  margin-top: 6px;
  margin-bottom: 6px;
}

.main-page-footer-item {
  font-family: sans-serif;
}

.sidepanel-staff-picks-header{
  height: 80px;
  width: 95%;
  margin-left: 5%;
  position: relative;
  font-weight: bolder;
  font-size: 20px;
}


.sidepanel-staff-picks-content {
  height: 100%;
  width: 100%;
  position: relative;
  flex-direction: column;
  overflow-y: scroll;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none;  /* For Internet Explorer and Edge */
}

.sidepanel-staff-picks-content::-webkit-scrollbar {
  display: none; /* For Chrome, Safari and Opera */
}


.sidepanel-tags-container {
  position: relative;
  top: 0px;
  height: 300px;
  width: 100%;
  border-top: #e6e6e6 solid 1px;

}

.sidepanel-tags {
  height: 300px;
  padding: 6px;
  overflow: hidden;
  transition: height 0.5s ease;
}

.sidepanel-tags-extended {
  height: 500px;
  transition: height 0.5s ease;
  overflow: scroll;
  overflow-x: hidden;
}



.sidepanel-tag-header {
  width: 80%;
  height: 30px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-size: 15px;
  font-weight: 500;
  /* margin-top: 30px; */
  transition: all 0.5s;
  font-weight: bolder;
  /* font-size: 18px; */
}

.sidepanel-tag-header.extended {
  margin-top: 5px;
  transition: all 0.5s;
}
