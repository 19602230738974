.expandtextarea-container {
  display: flex;
  width: 100%;
  height: 100%;
}

.expandtextarea-flex {
  flex-grow: 1;
  position: relative;
  padding: 8px;
}

.expandtextarea-text-display {
  white-space: pre-wrap;
  font-size: 14px;
  height: fit-content;
}

.expandtextarea-textarea {
  resize: none;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  overflow: hidden;
  font-size: 15px;
  padding: 0;
  margin: 0;
  border: rgba(128, 128, 128, 0.264) dotted 1px;



  color: #292929;
  line-height: 28px;
  font-family: source-serif-pro, Georgia, Cambria, "Times New Roman", Times, serif;
  font-size: 20px;
  font-weight: lighter;

}
