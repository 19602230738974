.story-tile-style3 {
  background-color: #fff;

  height: fit-content;
  width: 99%;
  left: 0%;
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  margin-top: 10px;
}



.style3-content{
  height: 70px;
  margin-left: 20px;

  display: flex;
  flex-direction: column;
}

.style3-author-container{
  display: flex;
  align-items: center;
  width: 240px;
  height: 30px;
  margin-bottom: 4px;
}

.style3-profile-image{
  width: 30px;
  height: 30px;
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid black;
  cursor: pointer;
}


.style3-profile-image img {
  object-fit: cover;
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 300px;
}

.style3-author-name{
  font-size: 14px;
  position: relative;
  margin-left: 10px;
}


.style3-story-title:hover,
.style3-author-name:hover{
  cursor: pointer;
}

.style3-story-title-container{
  margin-top: 5px;
  margin-bottom: 5px;
  height: fit-content;
  width: 80%;
  word-break:keep-all;
  text-overflow: ellipsis;
  font-weight: bold;
}
.style3-story-title{
  width:100%;
  font-size: 15px;

}



