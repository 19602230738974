.story-tile-two-skeleton {
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  width: calc(99% - 25px);
  position: relative;
  background-color: #dcdcdc;
  border: #e0e0e0 solid 1px;
  z-index: 1;
  overflow: hidden;
  height: 190px;
  margin-left: 25px;
  margin-top: 10px;
}
.story-tile-two-skeleton:first-child {
  margin-top: 40px;
}

.skeleton-style2-content {
  top: 5%;
  position: relative;
  height: 180px;
  display: flex;
  flex-direction: column;
  z-index: 3;
}


.skeleton-style2-author-container,
.skeleton-style2-story-title-container {
  background-image: linear-gradient(90deg, #b5b5b5 0px, rgba(159, 159, 159, 0.1) 40px, #b6b6b6 80px);
  background-size: 200% 200%;
  animation: shimmer 3s infinite linear;
  animation-delay: .1s;
  z-index: 3;
  position: relative;
}


.skeleton-style2-author-container {
  background-image: linear-gradient(90deg, #b5b5b5 0px, rgba(159, 159, 159, 0.1) 40px, #b6b6b6 80px);

  width: 120px;
  min-width: fit-content;
  height: 30px;
  justify-content: space-between;
  position: relative;
  background-color: #e0e0e0;
  margin-left: 5px;
  border-radius: 5px;
  z-index: 2;

}

.skeleton-style2-story-title-container {
  background-image: linear-gradient(90deg, #b5b5b5 0px, rgba(159, 159, 159, 0.1) 40px, #b6b6b6 80px);
  height: 30px;
  width: 50%;
  margin-top: 10px;
  background-color: #e0e0e0;
  margin-left: 5px;
  border-radius: 5px;
  z-index: 2;
}

.skeleton-style2-story-image{
  position: absolute;

  width: 30%;
  height: 95%;
  top: 2.5%;
  right: 5px;
  background-color: #e0e0e0;

}

