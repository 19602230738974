/* TODO Add site wide styles */

.flexcenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flexbetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.align-left {
  text-align: left;
  justify-content: left;
}


html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}


body {
  overflow-x: hidden;
  overflow-y: auto;
}


.header-text {
  font-family: serif;
}

.memo-text {
  font-family: sans-serif;
}

.flex {
  display: flex;
}

.modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  background-color: rgba(229, 229, 229, .95);
}

.modal-container-transparent {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  background-color: rgba(229, 229, 229, .1);

}



@keyframes shimmer {
  0% {
    background-position: -200px 0;
  }

  100% {
    background-position: calc(200px + 100%) 0;
  }
}


.shimmer {
  background-image: linear-gradient(90deg, #dadada 0px, rgba(159, 159, 159, 0.1) 40px, #b6b6b6 80px);

  background-image: linear-gradient(90deg, #7f7f7f8a 10px, rgba(136, 136, 136, 0.139) 40px, #cdcdcd 1000px);
  border: rgba(128, 128, 128, 0.222) solid 1px;
  background-size: 100% 100%;
  animation: shimmer 8s infinite linear;
  z-index: 2;
}