.creatortile-wrapper {
  display: flex;
  justify-content: center;
  /* cursor: pointer; */
}

.creatortile-container {
  margin-top: 30px;
  width: 100%;
  display: flex;
  justify-content: center;
  border-top: black solid 1px;
  border-bottom: black solid 1px;
}

.creatortile-container:hover {
  box-shadow: 0 10px 10px -5px rgba(211, 211, 211, 0.5), 0 -10px 10px -5px rgba(211, 211, 211, 0.5);
}

.creatortile-profile-image {
  height: 100px;
  width: 130px;
  border-radius: 50%;
  overflow: hidden;
}


.creatortile-author-container {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.creatortile-profile-image img {
  border-radius: 50%;
  height: 100%;
  width: 100%;
  object-fit: cover;
  position: relative;
}

.creatortile-names-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 70%;
  font-size: 25px;
  align-items: center;
}

.creatortile-username {
  font-size: 20px;
}

.socials-container {
  display: flex;
}

.linkedin-logo {
  height: 44px;
  width: 44px;
  cursor: pointer;
  margin-right: 4px;
}

.github-logo {
  height: 42px;
  width: 42px;
  cursor: pointer;
}

.github-logo {
  background-color: black;
  border-radius: 50%;
  border: 1px solid black;
}