.main-page-trending-banner-container{
  top: 40px;
  position: relative;
  width: 90%;
  max-width: 1400px;
  left: 50%;
  transform: translate(-50%,0);
  padding: 5px;

}

.main-page-trending-tile-container {
  height: fit-content;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: minmax(100px, auto);
  grid-gap: 30px;
  padding: 6px;
  transition: all 0.3s ease;
}



@media screen and (max-width: 1000px) {
    .main-page-trending-tile-container {
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: minmax(100px, auto);

  }
}

@media screen and (max-width: 750px) {
  .main-page-trending-tile-container {
    grid-template-columns: 1fr;
    grid-auto-rows: minmax(100px, auto);
    grid-gap: 15px;
    transition: all 0.3s ease;
  }
}

.trending-banner-header{
  position: relative;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  width: 190px;
  left: 3px;
  top: -5px;
  align-items: center;
  justify-content: space-between;
}



.trending-image-container{
  width: 25px;
  height: 25px;
}

.trending-image-container img {
  width: 27px;
  height: 27px;
  object-fit: cover;
}



