.comment-panel {
    transform: translateX(100%);
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    width: 300px;
    transition: transform 0.6s ease-in-out;
    background-color: #fafafa;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 4px 12px;
    z-index: 100;
    overflow-y: scroll;
    /* padding: 20px; */
}

.comment-button {
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    /* padding: 10px; */
}

.comment-icon {
    margin-right: 3px;
}

.comment-panel-open {
    transform: translateX(0);
    transition: transform 0.6s ease-in-out;

}

.comment-panel-closed {
    transform: translateX(100%);
    transition: transform 0.6s ease-in-out;
}

/* for some reason, in the jsx for this one, it has to be "class" and not "className" */
.responses {
    /* font-weight: lighter; */
    margin: 20px;
    font-size: 20px;
}

.new-comment {
    margin: 30px;
}

.most-relevant {
    margin-bottom: 20px;
    font-weight: bold;
    font-size: 14px;
}

.posted-comments {
    /* margin-top: 30px */
    margin: 20px;
}

.signed-out {
    margin: 20px;
    margin-bottom: 20px;
    font-weight: bold;
    font-size: 16px;
}