.signup-form-page-container {
  user-select: none;
}



.signup-form-page-container {
  z-index: 1000000;
  background-color: white;
  padding: 20px;
  border-radius: 2px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

}


.signup-header-container {
  width: 100%;
  height: fit-content;
  position: relative;
}

.signup-header {
  margin-top: 55px;
  font-size: 30px;
  font-weight: 100;
}


.signup-close-button {
  position: absolute;
  right: 40px;
  top: 40px;
}

.signup-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: fit-content;
  /* width: 400px; */
  margin: 0 auto;
  margin-top: 10px;
}


.email-field-invalid {
  color: red;
}


.signup-div label {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: calc(100%);
  right: 2%;
  margin-bottom: 10px;
  font-size: 20px;
  position: relative;
  box-sizing: border-box;
}

.signup-div input {
  width: 100%;
  margin-top: 5px;
  margin-bottom: 10px;
  border-radius: 20px;
  height: 35px;
  font-size: 17px;
  padding-left: 10px;
}



.signup-div-button {
  padding: 10px;
  position: relative;
  bottom: 20px;
  border-radius: 20px;
  height: 40px;
  width: 220px;
  border: 1px solid #33a245;
  color: #33a245;
  font-size: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.signup-div-button:hover {
  color: #ffffff;
  background-color: #33a245;
  font-size: 22px;
  transition: color 0.5s;
}

.disabled {
  color: #ffffff;
  background-color: #ce2525;

}

.disabled:hover {
  cursor: not-allowed;
  background-color: #d27777;
}



.alt-links {
  position: relative;
  width: 80%;
  display: flex;
  flex-direction: column;
  left: 10%;
  justify-content: center;
  align-items: center;
}


.signup-no-account-container {
  height: 40px;
  width: 260px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: -10px;

}

.create-one {
  color: #1A8917;
  font-weight: 800;
  font-size: 16px;
  position: relative;
  left: 2px;
  cursor: pointer;
}

.create-one:hover {
  color: rgb(36, 117, 49);
  font-weight: 800;
  font-size: 16px;
  position: relative;
  left: 2px
}

.signup-demo-container {
  color: #757575;
  margin-top: 50px;
  height: 40px;
  width: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 13px;
  padding: 0 10px;
}

.demo-user-signin {
  color: #757575;
  text-decoration: underline;
  cursor: pointer;
  margin: 0 5px;
}



.link {
  cursor: pointer;
}

.link:hover {
  cursor: pointer;
  text-decoration: underline;
}



.demo-user-signin {
  left: 14px;
  display: block;
  margin-top: 30px;
  color: #757575;
  text-decoration: underline;
  cursor: pointer;
}



.profile-image-buttons-container {
  height: 120px;
  width: 300px;
  margin: auto 0;
  margin-top: -10px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;


}

.profile-image-buttons-header {
  height: 30px;
  width: 100%;
  margin-bottom: 0px;
  font-size: 20px;
  display: flex;
  flex-direction: column;
}

.profile-image-buttons-header-note {
  font-size: 12px;
  display: flex;
  margin-bottom: 10px;


}

.profile-image-buttons {
  width: 100%;
  overflow-y: hidden;
  overflow-x: scroll;
  flex-direction: row;
  display: flex;
  overflow-x: auto;
  padding: 10px;
}

.signup-profile-image-button {
  border: black solid 1px;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  overflow: hidden;
  cursor: pointer;
  position: relative;

  flex: 0 0 auto;
  margin-right: 10px;
  cursor: pointer;
  position: relative;
  transition: transform 0.2s;
}

.signup-profile-image-button img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}


.profile-image-button.glowing {
  box-shadow: 0 0 10px #09f, 0 0 5px #09f;
}





.icon-selected {
  outline: 2px solid rgb(34, 171, 151);
}




@media screen and (min-width: 710px) {
  .signup-form-page-container {
    width: 650px;

    height: 90%;
    min-height: 600px;
    left: 50%;
    top: 2.5%;
    transform: translate(-50%, 0%);
  }


  .signup-header {
    margin-top: 40px;
    font-size: 30px;
    font-weight: 100;
  }

  .signup-close-button {
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
  }

  .signup-no-account-container {
    position: relative;
    bottom: 60px;
    margin-top: 35px;

  }

  .signup-div-button {
    position: relative;
    top: -10px;

  }


}