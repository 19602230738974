.ourstorypage {
  width: 100%;
}

/*  DIV 1 stylings */
.ourstorypage-banner-container {
  position: relative;
}

.ourstorypage-banner-content {
  position: relative;
}

/*  DIV 2 stylings */

.ourstorypage-story-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  overflow: hidden;
}

.ourstorypage-story-section {
  width: 50%;

}

.ourstorypage-story-content {
  line-height: 1.3;
  font-size: 18px;
  font-weight: 400;
  padding: 40px;
  border-right: 1px solid black;
}

.ourstorypage-story-image {
  position: relative;
  width: 100%;
  min-height: 100%;

}

.ourstorypage-story-image img {
  object-fit: cover;
  overflow: visible;
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 300px;
}









/*  DIV 3 stylings */

.ourstorypage-living-network-container {
  height: fit-content;
  flex-direction: column;
  background-color: #ffd1b9;
  width: 100%;
  position: relative;
}

.ourstorypage-living-network-header-container {
  flex-direction: column;
  max-width: 80%;
  font-size: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 30px;

}



.ourstorypage-living-network-content {
  font-size: 20px;
  margin-top: 20px;
  margin-bottom: 70px;
  font-weight: lighter;
  width: 100%;
  max-width: 600px;
  text-align: center;
}


/* DIV 4 Stylings */

.ourstorypage-creators-container {
  height: fit-content;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: minmax(85px, auto);
  grid-gap: 15px;
  padding-top: 0%;
  padding-bottom: 70px;
  width: 100%;
  background-color: #ffd1b9;
}

.ourstorypage-creator-tile {
  border-top: solid black 1px;
  border-bottom: solid black 1px;
  position: relative;
  width: 90%;
  left: 5%;
}

.ourstorypage-creator-tile:hover {
  cursor: pointer;
}

/* DIV 5 Stylings */
.ourstorypage-100-million-container {
  height: 100%;
  background-color: black;
  color: white;
  border-bottom: white solid 1px;
  display: flex;
  position: relative;
}

.ourstorypage-100-million-container::after {
  content: '';
  position: absolute;
  width: 1px;
  background: white;
  top: 0;
  bottom: 0;
  left: 50%;
}

.ourstorypage-100-million-section {
  width: 50%;
  height: 100%;
  box-sizing: border-box;
}

.ourstorypage-100-million-section.left {
  font-size: 40px;
  height: fit-content;
}

.ourstorypage-100-million-section.right {
  padding-left: 0.5em;
  font-size: 25px;
  height: fit-content;
}

.ourstorypage-100-million-content {
  width: 100%;
  position: relative;
  padding: 1%;
  width: 90%;
  margin-bottom: 10px;
}

.ourstorypage-quote-image-container {
  width: 100%;
  height: 200px;
  margin-top: 35px;
  overflow: hidden;
}

.ourstorypage-quote-image {
  position: relative;
  width: 80%;
  height: 80%;
  max-width: 350px;
}


.ourstorypage-quote-image img{
  object-fit: cover;
  position: absolute;
  width: 100%;
  height: 100%;
  transform: scale(1.5);
}

.ourstorypage-quote-container {
  position: relative;
  width: 90%;
  left: 5%;
  height: fit-content;
  font-size: 25px;
  margin-bottom: 50px;
  top: 20px;
}

.ourstorypage-quote-quote {
  padding-right: 15px;
  font-weight: lighter;
  max-width: 500px;
  font-size: 20px;
}


/* DIV 6 Stylings */
.ourstorypage-create-content-container {
  height: fit-content;
  background-color: black;
  color: white;
  flex-direction: column;
  padding-top: 45px;
  width: 100%;
  text-align: center;
}

.ourstorypage-create-content-header-container{
  font-size: 60px;
  font-weight: lighter;
  width: 60%;
  line-height: 1;


}

.ourstorypage-create-content {
  font-size: 19px;
  margin-top: 10px;
  margin-bottom: 60px;
  font-weight: lighter;
  max-width: 600px;
  text-align: center;
  width: 60%;
}

.ourstorypage-create-content-button {
  position: relative;
  bottom: 50px;
  border-radius: 20px;
  height: 40px;
  width: 220px;
  border: 1px solid #33a245;
  color: #33a245;
  font-size: 22px;
  margin-top: 30px;

}

.ourstorypage-create-content-button:hover {
  color: #ffffff;
  background-color: #33a245;
  font-size: 22px;
  transition: color 0.5s;
  cursor: pointer;
}

/* DIV 7 Stylings */

.ourstorypage-membership-container {
  height: 500px;
  background-color: #33a245;
  height: fit-content;
  flex-direction: column;
  font-size: 22px;
  padding-top: 15px;
  font-weight: lighter;
  text-align: center;
  width: 100%;
  border-bottom: black solid 1px;
}

.ourstorypage-membership-header {
  font-size: 60px;
  font-weight: lighter;
  width: 80%;
  line-height: 1;
  margin-top: 30px;
}

.ourstorypage-membership {
  margin-top: 30px;
  max-width: 660px;
}

.ourstorypage-membership-button {
  position: relative;
  bottom: 0px;
  border-radius: 20px;
  height: 40px;
  width: 280px;
  border: 1px solid #ffffff;
  color: #ffffff;
  font-size: 22px;
  margin-top: 40px;
  margin-bottom: 40px;
}

.ourstorypage-membership-button:hover {
  border: white 1px solid;
  color: #000000;
  background-color: #ffffff;
  font-size: 22px;
  transition: color 0.5s;
  cursor: pointer;
}

/* DIV 8 Stylings */
.ourstorypage-read-reward-container {
  height: fit-content;
  background-color: #33a245;
  display: flex;
  position: relative;
}

.ourstorypage-read-reward-container::after {
  content: '';
  position: absolute;
  width: 1px;
  background: black;
  top: 0;
  bottom: 0;
  left: 50%;
}

.ourstorypage-read-reward-section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 50%;
}

.ourstorypage-read-reward-header {
  width: 100%;
  text-align: left;
  left: 5%;
  margin-top: 30px;
  position: relative;
  left: 5%;
  font-size: 30px;
  height: 90px;
}



.ourstorypage-read-reward-image {
  height: 200px;
  width: 240px;
  margin-bottom: 0px;
  margin-top: -20px;
  position: relative;
  left: 2%;
}

.ourstorypage-read-reward-memo {
  position: relative;
  bottom: 5%;
  left: 5%;
  width: 80%;
  height: fit-content;
  min-height: 60px;
  font-size: 19px;
  font-weight: 100;
  margin-bottom: 20px;
  margin-top: -10px;


}

.ourstorypage-read-reward-image img {
  width: 200px;
  height: 200px;
  object-fit: contain;
  position: relative;
}






/* DIV 9 Stylings */

.ourstorypage-take-medium-container {
  height: fit-content;
  background-color: #b7e5a4;
  width: 100%;
  display: flex;
  border-bottom: black solid 1px;
  border-top: black solid 1px;
}

.ourstorypage-take-medium-section {
  height: fit-content;
  width: 50%;
  position: relative;
  left: 5%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}


.ourstorypage-take-medium-header{
  text-align: left;
  width: fit-content;
  width: 250px;
  text-align: left;
  line-height: 0.9;
  font-size: 60px;
  margin-top: 35px;
  margin-right: 30px;
}

.ourstorypage-take-medium-memo {
  font-family: serif;
  position: relative;
  display: flex;
  width: 90%;
  max-width: 450px;
  margin-top: 0px;
  margin-top: 35px;
  font-size: 19px;
}

.ourstorypage-download-buttons-container {
  display: flex;
  position: relative;
  width: 200px;

  margin-top: 20px;
  margin-bottom: 20px;
  
}

.ourstorypage-play-store-button,
.ourstorypage-apple-store-button {
  height: 18px;
  display: flex;
}

@media screen and (min-width: 600px) {
  .ourstorypage-play-store-button,
  .ourstorypage-apple-store-button {
    height: 40px;
  }
}

.ourstorypage-take-medium-image-container {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.ourstorypage-take-medium-image {
  width: 60%;
  left: 10%;
  position: relative;
  cursor: pointer;
}


.ourstorypage-play-store-button img,
.ourstorypage-apple-store-button img {
  width: 100%;
  height: 100%;
  min-width: 70px;
  min-height: 30px;
  object-fit: contain;
}

/* DIV 10 Stylings */

.ourstorypage-learn-more-container {
  display: flex;
  border-bottom: black solid 1px;
  width: 100%;
}

.ourstorypage-learn-more-section-left {
  border-right: black solid 1px;

  background-color: #ffffff;
  width: 50%;
  height: 700px;
  min-height: fit-content;
  text-align: left;
}

.ourstorypage-learn-more-section-right {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.ourstorypage-learn-more-section-right-top,
.ourstorypage-learn-more-section-right-bottom {
  border-bottom: black solid 1px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 20px;
  background-color: #ffffff;
  height: 350px;
}

.ourstorypage-learn-more-section-right-bottom {
  border: none;
}

.ourstorypage-learn-more-header,
.ourstorypage-work-header {
  font-size: 18px;
}

.ourstorypage-learn-more-content {
  font-size: 14px;
}

.ourstorypage-learn-more-header .header-text.align-left {
  font-size: 60px;
  margin-top: 40px;
  margin-left: 30px;
}

.ourstorypage-learn-more-header {
  font-size: 30px;
  margin-top: 15px;
  width: 80%;
}
.ourstorypage-learn-more-content {
  font-size: 20px;
  margin-top: 10px;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.ourstorypage-work-button,
.ourstorypage-blog-button {
  height: 40px;
  border-radius: 20px;
  border: 1px solid #33a245;
  color: #33a245;
  font-size: 22px;
  width: 200px;
  position: relative;
  bottom: 25px;
}

.ourstorypage-work-button {
  width: 220px;
}

.ourstorypage-work-button:hover,
.ourstorypage-blog-button:hover {
  color: #ffffff;
  background-color: #33a245;
  font-size: 22px;
  transition: color 0.5s;
  cursor: pointer;
}

/* DIV 11 Stylings */



.ourstorypage-expand-header {
  width: 90%;
  font-size: 60px;
  margin-top: 40px;
  margin-left: 30px;
}

.ourstorypage-expand-container {
  display: flex;
  height: 80vh;
}

.ourstorypage-box-container {
  width: 100%;
  height: 100%;
}

.ourstorypage-box-container img{
  object-fit: cover;
  overflow: visible;
  position: relative;
  width: 50%;
  height: 50%;
}

.ourstorypage-expand-section-left {
  width: 50%;
  border-right: solid 1px black;
  border-bottom: solid 1px black;
}

.ourstorypage-expand-section {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: left;
  border-bottom: solid 1px black;
}

.ourstorypage-started-button {
  height: 40px;
  border-radius: 20px;
  border: 1px solid #33a245;
  background-color: #33a245;
  color: #ffffff;
  font-size: 22px;
  align-self: left;
  margin-left: 5%;
  transition: all 0.5s;

  position: relative;
  bottom: 25px;
  width: 220px;

}

.ourstorypage-started-button:hover {
  background-color: #1f672b;
  transition: all 0.5s;
  cursor: pointer;
}

.ourstorypage-expand-header {
  width: 90%;
}

/* DIV 12 Stylings */

.ourstorypage-footer-container {
  flex-direction: column;
  justify-content: space-apart;
  height: 180px;
}

.ourstorypage-footer-logo {
  position: relative;
  top: 0px;
  font-size: 55px;
  cursor: pointer;
}

.ourstorypage-footer-buttons {
  display: flex;
  justify-content: space-around;
  width: 20%;
  min-width: 250px;
  position: relative;
  text-decoration: underline;
  font-size: 12px;
  top: 40px;
}

.ourstorypage-footer-button:hover {
  cursor: pointer;
  text-decoration: none;
}




/*  MEDIA QUERIES */

@media screen and (min-width: 455px) {
  .ourstorypage-banner-container {
    position: relative;
    width: 100%;
    top: 35px;
  }

  .ourstorypage-banner-content {
    font-size: 40px;
  }

  .ourstorypage-learn-more-section-right-bottom {
    padding-left: 10px;
  }


  
}

@media screen and (min-width: 550px) {
  .ourstorypage-learn-more-section-right-bottom {
    padding-left: 20px;
  }
  .ourstorypage-download-buttons-container {
    width: 250px;
    left: -15px;
  }

    /* .ourstorypage-take-medium-header{
      border: rgb(209, 63, 63) solid 1px;
      width: 600px;
    } */
  
}



@media screen and (min-width: 700px) {
  .ourstorypage-read-reward-image img {
    width: 280px;
    height: 280px;
    object-fit: contain;
    position: relative;
    bottom: 20%;
  }

  .ourstorypage-learn-more-section-right-bottom {
    padding-left: 20px;
  }
}


@media screen and (min-width: 799px) {
  .ourstorypage-banner-content {
    font-size: 65px;
  }

   /* .header-text {
    width: fit-content;
    text-align: center;
    font-size: 8vw;
    line-height: 0.9;
  } */

  .ourstorypage-quote-image-container {
    margin-top: 70px;
  }


  .ourstorypage-read-reward-header.header-text {
    font-size: 4vw;
    width: 100%;
  }

  .ourstorypage-living-network-content {
    width: 100%;
  }

  .ourstorypage-quote-container {
    margin-bottom: 100px;
  }

  .ourstorypage-take-medium.header-text {
    line-height: 0.9;
    font-size: 7.5vw;
  }

  .ourstorypage-take-medium-memo {
    margin-top: 30px;
  }


  .ourstorypage-play-store-button,
  .ourstorypage-apple-store-button {
    height: 50px;
    width: 170px;
  }

  .ourstorypage-download-buttons-container {
    width: 400px;
    left: -15px;
  }

  .ourstorypage-learn-more-header .header-text.align-left {
    font-size: 7vw;
    margin-top: 30px;
  }
}

@media screen and (max-width: 800px) {
  .ourstorypage-creators-container {
    grid-template-columns: repeat(1, 1fr);
  }
  .ourstorypage-creators-tile {
    width: 90%;
  }
}

@media screen and (min-width: 1005px) {
  .ourstorypage-100-million-section.left {
    font-size: 5vw;
    height: fit-content;
  }

  .ourstorypage-read-reward-header {
    width: 90%;
    font-size: 3.5vw;
  }


}

@media screen and (max-width: 974px) {
  .ourstorypage-banner-container {
    height: 370px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (min-width: 975px) {
  .ourstorypage-banner-container {
    height: calc(18vw + 155px);
    max-height: 420px;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 10%;
  }

  .ourstorypage-banner-content {
    font-size: 7vw;
    top: 10%;
  }

  .ourstorypage-story-content {
    padding-left: 80px;
    padding-right: 80px;
    padding-top: 45px;
    padding-bottom: 45px;
  }


}

@media screen and (min-width: 990px) {
  .ourstorypage-take-medium-header{
    width: 600px;
  }

}
