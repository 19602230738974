.feedpage-container {
  flex-direction: row;
  max-width: 1200px;
  width: 100%;
  position: relative;
  margin: 0 auto;
  top: 55px;
}


.storyfeed-wrapper{
  width: 65%;
  border-right: #e6e6e6 solid 1px;

}

.storyfeed-wrapper.wide{
  width: 100%;
}


.sidepanel-wrapper{

  position: relative;
  width: 35%;
  min-width: 300px;
  height: 1000px;
  position: sticky;
  top: -450px;
}

