

.createstory-container {
  top: 70px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.article-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 800px;
  min-height: 600px;
  margin: auto;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 4px;
  background-color: #fff;
  overflow-y: auto;
  overflow-x:hidden;
  height: fit-content;
}



.title-input-container{
  width: 100%;
  position: relative;
}


.title-input {
  font-size: 40px;
  width: 100%;
  margin-bottom: 10px;
  border: none;
  outline: none;
  height: fit-content;
}

.title-input::placeholder {
  color: #b3b3b1;
}

.title-label{
  position: absolute;
  left: -40px;
  top: 20px;
  z-index: 10;
}


.input-label{
  width: 90%;
  height: fit-content;
  border: none;
  display: flex;
}





.alt-text-input {
  min-height: 30px;
  font-size: 1em;
  resize: none;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  font-size: 1.2em;
  border: none;
  outline: none;
  
}



.text-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%; 
  margin-top: 30px;
  margin-bottom: 10px;
  flex-direction: row;
  height: fit-content; 
  height: calc(100% - 100px);

}


.text-container {
  width: 100%;
}








/* .story-image {
  display: flex;
  margin: 0 auto;
  max-width: 70%;
  height: auto;
  width: 70%;
  border-radius: 20px;
  border: 1px solid black;
} */



.image-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%; 
  max-height: 400px; 
  margin-bottom: 10px;
  flex-direction: row;
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%; 
  max-width: 400px; 
  max-height: 400px; 
  height: auto; 
  margin-bottom: 10px;
  flex-direction: column;
}


.image-container img {
  object-fit: contain;
  width: 100%; 
  height: 100%; 
 max-width: 400px; 
  max-height: 400px; 
}

.delete-button {
  position: relative;
  right: 10px;
  top: 0px;
  color: white;
  cursor: pointer;
}


.createstorypage-buttons-container {
  position: fixed;
  bottom: 10px;
  left: 10px;
  width: 50px;
  height: 50px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.255);
  border-radius: 15px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: 2s ease all;
}

.createstorypage-buttons-container:hover {
  width: 155px;
  transition: .5s ease all;
}

.createstorypage-add-content-button{
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: green solid 1px;
  margin-right: 10px;
  position: relative;
  left: 2px;
}

.createstorypage-add-content-button{
  width: 25px;
  height: 25px;
  margin-left: 10px;
  border: green solid 1px;
}



.add-button {
  margin: 5px;
  padding: 10px;
  font-size: 1em;
  border-radius: 4px;
  border: 1px solid #ccc;
  cursor: pointer;
}


.publish-container{
  width: 100%;
  height: 20px;
  position: relative;
  top: -6px;
  display: flex;
  justify-content: flex-end;
}

.publish-container.bottom{
  width: 100%;
  height: 20px;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  bottom: 0px;
}

.createstorypage-publish{
  background-color: green;
  color: white;
  width: 70px;
  height: 20px;
  padding: 2px;
  border-radius: 15px;
  position: relative;

  font-weight: 300;
  font-size: 14px;
  cursor: pointer;
  user-select: none;
} 

.createstorypage-publish:hover{
  background-color: rgb(0, 93, 0);
} 
.createstorypage-publish.disabled{
  background-color: rgba(0, 128, 0, 0.214);

}