/* margin-top 75 px so that everything is under the nav bar and not being covered*/


.storypageskeleton-story-title {
  font-size: 30px;
  font-weight: bold;
  color: #292929;
  font-family: sans-serif;
  width: 500px;
  height: 40px;
}

.storypageskeleton-authorname {
  width: 120px;
}


.storypageskeleton-time {
  color: #757575;
  font-family: sans-serif;
  font-weight: lighter;
  font-size: 14px;
  margin-top: 3px;
  width: 50px;
  height: 18px;
}


.storypageskeleton-story-content {
  color: #757575;
  margin-top: 3px;
  width: 50px;
  height: 18px;
}

.storypageskeleton-story-content.large {
  width: 100%;

}

.storypageskeleton-story-content.med {
  width: 50%;
}

.storypageskeleton-story-content.small {
  width: 25%;
}



.member-only {
  color: #757575;
  font-family: sans-serif;
  font-weight: lighter;
  margin-top: 30px;
  font-size: 14px;
  display: flex;
  /* justify-content: center; */
  align-items: center;
}

.shining-star {
  margin-right: 2px;
  margin-bottom: 2px;
}

/* .story-page {
  max-width: 800px;
  margin: 0 auto;
  padding: 70px;
} */

.triple-dots-icon {
  cursor: pointer;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 90;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
}

.overlay.active {
  opacity: 1;
  visibility: visible;
}



.author-section {
  display: flex;

  margin-top: 30px;

}


.author-image {
  height: 50px;
  width: 50px;
  margin-right: 10px;
  border: 2px solid black;
  border-radius: 30px;
  cursor: pointer;

}

.author-information {
  width: fit-content;
  display: flex;

  align-items: left;
  cursor: pointer;
  flex-direction: column;

}

.author-name-and-follow {
  display: flex;
  flex-direction: row;
}



.story-author {
  font-size: 16px;
  font-weight: lighter;
  margin-top: 0px;
  margin-bottom: 0px;
  color: #292929;
  font-family: sans-serif;
}

.follow-unfollow-button {
  color: #479275;
  font-size: 16px;
  font-family: sans-serif;
  cursor: pointer;
  background: none;
  border: none;
}



.options-bar {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 30px;
  border-top: 1px solid rgb(242, 242, 242);
  border-bottom: 1px solid rgb(242, 242, 242);
  padding: 8px 3px;
  font-family: sans-serif;
}



.comment-button {
  cursor: pointer;
  border: none;
  border-radius: 20px;
  padding: 5px 10px;
  font-size: 16px;
}

.alt-options {
  font-family: sans-serif;
}

.alt-options button {
  display: inline-block;
  padding: 8px 15px;
  font-size: 16px;
  text-align: center;
  color: #757575;
  background-color: white;
  border: 1px solid #757575;
  border-radius: 20px;
  cursor: pointer;
  margin-right: 5px;
  margin-bottom: 20px;
  margin-top: 10px;
}

.story-content {
  margin-top: 30px;
  margin-bottom: 0px;
  /* text-align: center; */
  color: #292929;
  line-height: 28px;
  font-family: serif;
  font-size: 20px;
  font-weight: lighter;
}

.story-tag {
  border: none;
  margin-top: 30px;
}

.story-image {
  display: flex;
  margin: 20px auto;
  max-width: 70%;
  /* min-height: 70px; */
  height: auto;
  /* margin-top: 10px;
  margin-bottom: 10px; */
  width: 70%;
  /* border-radius: 20px; */
  border: 1px solid black;
  /* box-shadow: 4px 4px 0px 0px rgba(0, 0, 0, 1); */

}



.loading-message {
  font-size: 20px;
  font-weight: bold;
  margin-top: 20px;
  color: #777;
}

.footer {
  background-color: #fafafa;
  width: 100%
}



.clap-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.unclap-button,
.clap-button {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background: none;
  border: none;
  font-size: 1em;
  cursor: pointer;
  margin: 0 5px;
  position: relative;
  transition: color 0.3s ease;
  border: rgb(255, 255, 255) solid 1px;
  display: flex;
  align-items: center;
  justify-content: center;

}


.unclap-button:hover,
.clap-button:hover {
  background-color: #f0f0f0;
}



.clap-button:hover {
  border: green solid 1px;
  color: green;
}

.unclap-button:hover {
  border: rgb(128, 2, 0) solid 1px;

  color: rgb(128, 2, 0);
}

.clap-content {
  display: flex;
  align-items: center;
  position: relative;
}

.claps-icon {
  width: 24px;
  height: 24px;
}

.claps-count {
  margin-left: 5px;
}

.members-only-div {
  width: 100%;
  height: 100%;
  backdrop-filter: blur(10px);
  border: black solid 1px;

}

.blur {
  filter: blur(10px);

}