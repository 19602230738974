.storyfeed-container{
  width: 100%;
  position: relative;
}

.hidden{
  visibility: hidden;
}

.feed-nav {
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-bottom: rgba(73, 73, 73, 0.2) solid 1px ;
  box-shadow: 2px 0  0 2px rgba(73, 73, 73, 1) solid 1px ;
  height: 55px;
} 

.feed-select-container{
  display: flex;
  width: 100%;
  height: 100%;
  overflow-x: scroll;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none;  /* For Internet Explorer and Edge */
}

.feed-select-container::-webkit-scrollbar {
  display: none; /* For Chrome, Safari and Opera */
}

.feed-select{
  height: 100%;
  min-width: fit-content;
  padding-left: 10px;
  padding-right: 10px;
  width: 70px;
  color: #646464;
  font-size: 14px;
  font-weight: 100;
  cursor: pointer;
  margin-right: 4px;
  margin-left: 4px;
  box-sizing: border-box;
  border-bottom: #ffffff solid 1px;

}

.feed-select.small{
  padding-left: 10px;
  padding-right: 10px;
  width: 20px;
  margin-right: 4px;
  margin-left: 4px;
}

.feed-select.med{
  padding-left: 10px;
  padding-right: 10px;
  width: 20px;
  margin-right: 4px;
  margin-left: 4px;
}

.feed-select.dyna{
  padding-left: 10px;
  padding-right: 10px;
  width: fit-content;
  margin-right: 4px;
  margin-left: 4px;
  overflow: hidden;
}





.feed-select.selected{
  color: black;
  font-weight: 300;
  border-bottom: #000000 solid 1px;
}

.add-container{
  border-radius: 50%;
  height: 25px;
  width: 25px;
}
.add-container:hover{
  background-color: #ededed;
}

.search-close-tab{
  position: relative;
  top: -5px;
  left: -5px;
  font-size: 10px;
  user-select: none;
  cursor: pointer;
}


.feed-header {
  height: 0px;
  padding: 6px;
  overflow: hidden;
  transition: height 0.5s ease;
}


.feed-header.extended {
  height: 50px;
  transition: height 0.5s ease;
  display: flex;
  justify-content: space-between;
  top: 50px;
  z-index: 1;
}

.header-title{
  font-size: 40px;
  margin-left: 20px;
  margin-top: 20px;
  height: fit-content;
  width: fit-content;
}


.search-nav {
  position: relative;
  display: flex;
  justify-content: space-between;
  top: 0px;
  left: 0px;
  width: 110%;
  border-bottom: rgba(73, 73, 73, 0.2) solid 1px ;
  box-shadow: 2px 0  0 2px rgba(73, 73, 73, 1) solid 1px ;
  height: 55px;


} 

