.main-page-content {
  border-top: solid rgba(0, 0, 0, 0.154) 1px;
  position: relative;
  width: 100%;
  max-width: 1200px;
  left: 50%;
  margin-top: 40px;
  transform: translate(-50%, 0);
  height: fit-content;
}

.small-view {
  position: relative;
  width: 100%;

  display: flex;
  justify-content: center;
  flex-direction: column;
}

.wide-view {
  width: 100%;

  display: flex;
  justify-content: center;
  flex-direction: row;
}

.main-page-footer-tags-container {
  position: sticky;
  top: 75px;
  width: 35%;
  height: 300px;
}

.main-page-wide-feed {
  position: relative;
  top: 0px;
  width: 65%;
  height: fit-content;
}

.main-page-feed-article {
  width: 100%;
  height: 300px;
  border-bottom: black solid 1px;
}

.main-page-footer {
  border-top: solid rgba(0, 0, 0, 0.154) 1px;
  position: relative;
  top: 10px;
  width: 100%;
  height: 900px;
}



.skeleton-tag {

  background-size: 100% 100%;
  animation-delay: 0.1s;
  z-index: 2;

  border-radius: 15px;
  white-space: nowrap;
  width: 90px;
  height: 30px;
  padding: 5px;
  padding-left: 15px;
  padding-right: 15px;
  box-sizing: border-box;
  display: inline-block;
  margin: 5px;
  background-color: #f2f2f2;
}

.main-page-small-view-tags {
  position: relative;
  height: 115px;
  padding: 5px;
  overflow: hidden;
  width: 90%;
  left: 5%;
  transition: height 0.5s ease;
  overflow-x: hidden;
}

.main-page-small-view-tags-extended {
  position: relative;
  width: 90%;
  left: 5%;
  height: 300px;
  padding: 5px;
  transition: height 0.5s ease;
  overflow: scroll;
  overflow-x: hidden;
}

.divider-line {
  border-top: solid rgba(0, 0, 0, 0.154) 1px;
  width: 100%;
  height: 60px;
  position: relative;
  top: 45px;
}

.see-more-topics {
  top: 5px;
  left: 5%;
  position: relative;
  color: #198917;
  width: fit-content;
  cursor: pointer;
  font-size: 14px;
  font-weight: 300;
}

.see-more-topics.small {
  top: 25px;
}


.main-page-tags-wrapper{
  justify-content: center;
  display: flex;
}

.main-page-tags-extended {
  height: 500px;
  transition: height 0.5s ease;
  overflow: scroll;
  overflow-x: hidden;
}

.main-page-tags {
  height: 300px;
  width: 80%;
  padding: 6px;
  overflow: hidden;
  transition: height 0.5s ease;
}
.main-page-tags.loaded {
  height: 300px;
  width: 100%;
  padding: 6px;
  overflow: hidden;
  transition: height 0.5s ease;
}

.main-page-tag {
  border-radius: 15px;
  white-space: nowrap;
  padding: 8px;
  padding-left: 15px;
  padding-right: 15px;
  box-sizing: border-box;
  display: inline-block;
  margin: 5px;
  background-color: #f2f2f2;
  transition: all 0.2s;
  font-size: 15px;
  font-weight: 300;
}

.main-page-tag:hover {
  cursor: pointer;
  background-color: #e6e6e6;
}

.main-page-footer-item {
  border-radius: 10px;
  white-space: nowrap;
  padding: 9px;
  box-sizing: border-box;
  display: inline-block;
  margin: 1px;
  cursor: pointer;
}

.main-page-small-view-footer-item {
  position: relative;
  top: 5px;
  border-radius: 10px;
  white-space: nowrap;
  padding: 9px;
  box-sizing: border-box;
  display: inline-block;
  color: white;
  position: relative;
  top: 20px;
  left: 5%;
}

.main-page-content-header {
  height: 70px;
}
.main-page-small-view-content-header {
  height: 20px;
}

.main-page-tag-header {
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-weight: 500;
  margin-top: 30px;
  transition: all 0.5s;
}

.main-page-tag-header.extended {
  margin-top: 0px;
  transition: all 0.5s;
}

.main-page-small-view-footer {
  width: 100%;
  background-color: black;
  height: 150px;
  position: relative;
  left: 0%;
}

.main-page-small-view-tag-header {
  width: 100%;
  height: 30px;
  display: flex;
  align-items: flex-start;
  font-size: 15px;
  font-weight: 500;
}

.footer-logo {
  color: rgb(255, 255, 255);
  background-color: rgb(0, 0, 0);
  width: 100px;
  height: 100px;
  display: flex;
  position: relative;
  top: 0px;
  left: 5%;
}

.footer-logo img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.main-page-small-view-footer-item:hover,
.footer-logo:hover {
  cursor: pointer;
}
