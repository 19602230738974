.story-tile-style1 {
  background-color: #fff;
  height: 100%;
  width: 99%;
  left: 0%;
  position: relative;
  display: flex;
}

.story-tile-style1:last-child {
  margin-bottom: 15px;
}


.style1-number{
  width: 50px;
  font-size: 35px;
  color: #e6e6e6;
  position: relative;
  top: -5px;
}

.style1-content{
  height: 100%;
  display: flex;
  flex-direction: column;
}

.style1-author-container{
  display: flex;
  align-items: center;
  width: 240px;
  height: 40px;
}

.style1-profile-image{
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: black solid 1px;
  overflow: hidden;
  cursor: pointer;
}

/* .style1-profile-image img {
  object-fit: cover;
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 300px;
} */

.style1-author-name{
  font-size: 14px;
  /* width: 100%; */
  position: relative;
  margin-left: 10px;
  /* font-weight: bolder; */
}


.style1-story-title:hover,
.style1-author-name:hover{
  cursor: pointer;
}

.style1-story-title-container{
  margin-top: 5px;
  height: 30px;
  height: fit-content;
  width: 100%;
  max-width: 280px;
  word-break:keep-all;
  text-overflow: ellipsis;
}

/* display: block; */





.style1-story-title{
  font-weight: bold;
}

.style1-date-read-time-container{
  width: fit-content;
  align-items: center;
  color: #757575;
  font-size: 13px;
  margin-top: 10px;
  position: relative;
}


@media screen and (min-width: 749px) {
  .style1-date-read-time-container{
    margin-top: 10px;
  }
}

.style1.fa-circle{
  transform: scale(.15);
}
