.mmm-container {
  position: absolute;
  top: 0px;
  width: 1100px;
  height: 100%;
  left: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
}


@media screen and (min-width: 1000px) {
  .mmm-container {
    position: absolute;
    top: 0px;
    width: 100%;
    height: 100%;
    left: 0px;
    display: flex;

  }
}
